@import "fonts";
@import "colors";

.footerHolder {
  width: 100vw;
  background-color: $pale-grey;
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    color: black;
  }

  a:visited {
    color: $dark-grey-blue;
  }

  .footer {
    width: 100%;
    height: 110rem;
    //max-width: 1200rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30rem;

    .footerLeft {
      flex: 1;
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;

      .footerLeftTextContainer{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 18rem;
        color: black;
      }
    }

    .footerMiddle {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .footerLogo {
        width: 86rem;
      }

      .footerMiddleText{
        font-size: 13rem;
      }

      .footerMiddleGrayText{
        font-size: 14rem;
        color: #c9c9c9;
        font-family: LucidaGrande;
      }


      .rights {
        @include Text-Style-Rights;
      }
    }

    .footerContact {
      flex: 1;
      width: 20%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;

      .footerLeftText{
        font-size: 18rem;
        color: black;
        white-space: nowrap;
      }

    }
  }
  @media only screen and (max-width: 599px) {
    .footer {
      padding: 10rem 30rem;
      .footerContact, .footerLeft {
        justify-content: flex-start;
      }
      .footerMiddle {
        margin-top: 20rem;
      }
      .footerLogo {
        //width: ;
      }
    }
  }
}
