@import "fonts";
@import "colors";

.radio-group {
  padding: 10rem 0;
  display: flex;
  background: #fbfbfb;

  .radio-wrapper {
    display: flex;
    align-items: center;

    .radio-label {
     font-size: 25rem;
      flex-wrap:wrap;
    }

    .radio {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5rem;
      margin: 0 10rem;

      label {
        display: flex;
        align-items: center;
      }

      [type="radio"]:not(:checked),
      [type="radio"]:checked {
        display: none;
      }

      [type="radio"] + label {
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      [type="radio"] + label:before {
        content: '';
        display: inline-block;
        box-sizing: border-box;
        width: 25rem;
        height: 25rem;
        transition: all 0.3s ease;
        background-clip: content-box;
        padding: 3rem;
      }

      [type="radio"]:not(:checked) + label:before {

        border-radius: 50%;
        border: 1px solid $peacock-blue;
      }

      [type="radio"]:checked + label:before {
        border-radius: 50%;
        border: 1px solid $peacock-blue;
        background-color: $peacock-blue;
      }
    }
  }

}