@import 'colors';

/* Phone */
@media (max-width: 599px) {
  html {
    font-size: 0.6px;
    //font-size: 75%;
  }
}

/* Tablet Portrait */
@media (min-width: 600px) and (max-height: 1366px){
  html {
    font-size: 0.85px;
  }
}

/* Tablet Landscape */
@media (min-width: 900px) {
  html {
    font-size: 0.85px;
  }
}

/* Tablet Landscape */
@media (min-width: 1024px) {
  html {
    font-size: 0.75px;
  }
}



/* Desktop */
@media (min-width: 1200px) {
  html {
    font-size: 1px;
  }
}

/* Laptop */
@media (min-width: 1200px) and (max-height: 700px){
  html {
    font-size: 0.75px;
  }
}


/* Large Desktop */
@media (min-width: 1800px) {
  html {
    font-size: 1px;
  }
}

@font-face {
  font-family: Assistant;
  src: local(Assistant-Light),
  url(../fonts/Assistant-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Assistant;
  src: local(Assistant-Regular),
  url(../fonts/Assistant-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Assistant;
  src: local(Assistant-SemiBold),
  url(../fonts/Assistant-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Assistant;
  src: local(Assistant-ExtraBold),
  url(../fonts/Assistant-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@mixin main-headline {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 1.02px;
  text-align: center;
  color: #282525;
}

@mixin headline {
  font-family: Assistant, sans-serif;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 0.91rem;
  text-align: center;
  color: $peacock-blue;
}
@mixin headline-second {
  font-family: Assistant, sans-serif;
  font-size: 26rem;
  letter-spacing: 0.91rem;
  text-align: center;
  color: $black;
}
body {
  font-family: Assistant, sans-serif;
  font-size: 16rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.49rem;
  text-align: center;
  color: $black;
}
@mixin button {
  font-family: Assistant, sans-serif;
  font-size: 22rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.77rem;
  text-align: center;
  color: $white;
}
@mixin input-field {
  font-family: Assistant, sans-serif;
  font-size: 16rem;
  letter-spacing: 0.56rem;
  text-align: right;
  color: $mushroom;
}
@mixin small-body {
  font-family: Assistant, sans-serif;
  font-size: 14rem;
  letter-spacing: 0.53rem;
  text-align: right;
  color: $black;
}










//////////////////////////////////////////////////////////////

@mixin Text-Style-Logo {
  font-family: Assistant;
  font-size: 82rem;
  font-weight: 800;
  letter-spacing: 0.78rem;
  text-align: center;
  color: $dark-grey-blue;
}

@mixin Text-Style-Heading-2 {
  font-family: Assistant;
  font-size: 50rem;
  font-weight: 800;
  letter-spacing: 0.61rem;
  text-align: center;
  color: $slate-grey;
}

@mixin Text-Style-Heading-3 {
  font-family: Assistant;
  font-size: 35rem;
  font-weight: 800;
  letter-spacing: 0.57rem;
  color: $slate-grey;
}

@mixin Text-Style-SemiHeading {
  font-family: Assistant;
  font-size: 50rem;
  font-weight: 600;
  letter-spacing: 0.69rem;
  color: $slate-grey;
}

@mixin Text-Style-SemiHeading-2 {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 0.55rem;
  color: $slate-grey;
}

@mixin Text-Style-Desc {
  font-family: Assistant;
  font-size: 25rem;
  font-weight: 600;
  line-height: 1.32;
  letter-spacing: 0.59rem;
  color: $slate-grey;
}


@mixin Text-Style-Desc-2 {
  font-family: Assistant;
  font-size: 25rem;
  line-height: 1.28;
  letter-spacing: 0.46rem;
  color: $slate-grey;
}

@mixin Text-Style-Label {
  font-family: Assistant;
  font-size: 35rem;
  font-weight: 600;
  letter-spacing: 0.57rem;
  color: $slate-grey;
}

@mixin Text-Style {
  font-family: Assistant, sans-serif;
  font-size: 29rem;
  line-height: 1.38;
  letter-spacing: 3.68rem;
  color: $slate-grey;
}

@mixin Text-Style-2 {
  font-family: Assistant, sans-serif;
  font-size: 24rem;
  font-weight: 800;
  letter-spacing: 0.3rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-3 {
  font-family: Assistant, sans-serif;
  font-size: 25rem;
  font-weight: bold;
  letter-spacing: 0.38rem;
  color: $slate-grey;
}

@mixin Text-Style-4 {
  font-family: Assistant;
  font-size: 17rem;
  line-height: 1.06;
  letter-spacing: 0.45rem;
  color: $slate-grey;
}

@mixin Text-Style-6 {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.51rem;
  color: $spruce;
}

@mixin Text-Style-7 {
  font-family: Assistant;
  font-size: 22rem;
  letter-spacing: 0.34rem;
  color: $pinkish-grey;
}

@mixin Text-Style-8 {
  font-family: Assistant;
  font-size: 45rem;
  font-weight: bold;
  letter-spacing: 0.76rem;
  color: $spruce;
}

@mixin Text-Style-16 {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 0.51rem;
  color: $slate-grey;
}

@mixin Text-Style-17 {
  font-family: Assistant;
  font-size: 30rem;
  letter-spacing: 0.51rem;
  color: $slate-grey;
}

@mixin Text-Style-11 {
  font-family: Assistant;
  font-size: 14rem;
  letter-spacing: 0.29rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-12 {
  font-family: Assistant;
  font-size: 24rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-13 {
  font-family: Assistant;
  font-size: 18rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-14 {
  font-family: Assistant;
  font-size: 24rem;
  font-weight: 800;
  letter-spacing: 0.3rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-18 {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 0.57rem;
  color: $spruce;
}

@mixin Text-Style-19 {
  font-family: Assistant;
  font-size: 26rem;
  font-weight: 800;
  letter-spacing: 0.49rem;
  color: $slate-grey;
}

@mixin Text-Style-23 {
  font-family: Assistant;
  font-size: 18rem;
  letter-spacing: 0.3rem;
  text-align: right;
  color: $slate-grey;
}

@mixin Text-Style-24 {
  font-family: Assistant;
  font-size: 40rem;
  font-weight: 600;
  letter-spacing: 0.76rem;
  color: $slate-grey;
}

@mixin Text-Style-25 {
  font-family: Assistant;
  font-size: 22rem;
  font-weight: 600;
  letter-spacing: 0.29rem;
  color: $slate-grey;
}

@mixin Text-Style-26 {
  font-family: Assistant;
  font-size: 21rem;
  line-height: 1.57;
  letter-spacing: 0.83rem;
  color: $slate-grey;
}

@mixin Text-Style-27 {
  font-family: Assistant;
  font-size: 25rem;
  font-weight: bold;
  letter-spacing: 0.41rem;
  color: $dark-grey-blue;
}

@mixin Text-Style-28 {
  font-family: Assistant;
  font-size: 25rem;
  letter-spacing: 0.41rem;
  color: $dark-grey-blue;
}

@mixin Text-Style-29 {
  font-family: Assistant;
  font-size: 64rem;
  font-weight: 300;
  letter-spacing: 0.98rem;
  color: $slate-grey;
}

@mixin Text-Style-30 {
  font-family: Assistant;
  font-size: 31rem;
  line-height: 1.29;
  letter-spacing: 3.3rem;
  color: $slate-grey;
}

@mixin Text-Style-31 {
  font-family: Assistant;
  font-size: 26rem;
  line-height: 1.54;
  letter-spacing: 3.3rem;
  color: $slate-grey;
}

@mixin Text-Style-32 {
  font-family: Assistant;
  font-size: 24rem;
  font-weight: 300;
  letter-spacing: 0.4rem;
  color: $black;
}

@mixin Text-Style-33 {
  font-family: Assistant;
  font-size: 50rem;
  font-weight: 600;
  letter-spacing: 0.62rem;
  color: $slate-grey;
}

@mixin Text-Style-34 {
  font-family: Assistant;
  font-size: 26rem;
  line-height: 1.27;
  letter-spacing: 0.61rem;
  color: $slate-grey;
}

@mixin Text-Style-35 {
  font-family: Assistant;
  font-size: 25rem;
  font-weight: bold;
  letter-spacing: 0.41rem;
  color: $slate-grey;
}

@mixin Text-Style-36 {
  font-family: Assistant;
  font-size: 20rem;
  line-height: 1.5;
  letter-spacing: 0.33rem;
  color: $slate-grey;
}

@mixin Text-Style-37 {
  font-family: Assistant;
  font-size: 45rem;
  font-weight: 300;
  letter-spacing: 0.62rem;
  color: $slate-grey;
}

@mixin Text-Style-38 {
  font-family: Assistant;
  font-size: 45rem;
  letter-spacing: 0.6rem;
  color: $slate-grey;
}

@mixin Text-Style-39 {
  font-family: Assistant;
  font-size: 17rem;
  letter-spacing: 0.3rem;
  color: $slate-grey;
}

@mixin Text-Style-40 {
  font-family: Assistant;
  font-size: 30rem;
  font-weight: bold;
  letter-spacing: 0.51rem;
  color: $spruce;
}

@mixin Text-Style-41 {
  font-family: Assistant;
  font-size: 19rem;
  letter-spacing: 0.51rem;
  color: $slate-grey;
}

@mixin Text-Style-42 {
  font-family: Assistant;
  font-size: 27rem;
  font-weight: 600;
  letter-spacing: 0.46rem;
  color: $spruce;
}

@mixin Text-Style-Logo-Footer {
  font-family: Assistant;
  font-size: 59rem;
  font-weight: bold;
  letter-spacing: 0.56rem;
  color: $dark-grey-blue;
}

@mixin Text-Style-Rights {
  font-family: Assistant;
  font-size: 22rem;
  font-weight: bold;
  letter-spacing: 0.21rem;
  color: $slate-grey;
}

@mixin Text-Style-Contact {
  font-family: Assistant;
  font-size: 35rem;
  letter-spacing: 0.58rem;
  color: $dark-grey-blue;
}

@mixin Text-Style-HeadingSemi {
  font-family: Assistant;
  font-size: 67rem;
  font-weight: 600;
  letter-spacing: 0.83rem;
  color: $slate-grey;
}

@mixin Text-Style-5 {
  font-family: Assistant;
  font-size: 45rem;
  font-weight: 300;
  letter-spacing: 0.76rem;
  color: $slate-grey;
}

@mixin Text-Style-Text-Area-Placeholder {
  font-family: Assistant;
  font-size: 24rem;
  letter-spacing: 0.37rem;
  color: $pinkish-grey;
}

@mixin Text-Style-Text-Area {
  font-family: Assistant;
  font-size: 24rem;
  letter-spacing: 0.37rem;
  color: $slate-grey;
}

@mixin Text-Style-Form-Sub-Title {
  font-family: Assistant;
  font-size: 45rem;
  font-weight: 300;
  letter-spacing: 0.76rem;
  color: $slate-grey;
}

@mixin Text-Style-Button-Big {
  font-family: Assistant;
  font-size: 38rem;
  font-weight: 600;
  letter-spacing: 0.63rem;
  color: $white;
}

@mixin Text-Style-Button-Small {
  font-family: Assistant;
  font-size: 27rem;
  font-weight: 600;
  letter-spacing: 0.61rem;
  color: $white;
}

@mixin Text-Style-9 {
  font-family: Assistant;
  font-size: 9rem;
  line-height: 1.11;
  letter-spacing: -0.14rem;
  text-align: center;
  color: $greyish;
}
