@import "../../../styles/fonts";

.history-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .title{
    @include main-headline;
    font-size: 40rem;
    font-weight: normal;
  }

  .history-container {
    width: 100%;
    margin: 50rem 0;
    .ReactTable{
      border-radius: 11px;
      box-shadow: 1px 2px 17px 0 rgba(170, 199, 217, 0.3);
      border: solid 4px $peacock-blue;
      background-color: $white;
      padding: 30px 45px;

      .service-icon-wrapper {
        > .icon {
          width: 35rem;
          height: 35rem;
        }
        > .label {
          font-size: 16rem;
          text-align: center;
          color: $peacock-blue;
        }
      }

      .rt-thead{
        &.-header{
          box-shadow: none;
        }
        .rt-th{
          color: $peacock-blue;
          font-weight: bold;
          font-size: 22rem;
          border: none;
          border-bottom: solid 1px $peacock-blue;

          &:focus{
            border-left-color: white !important;
            box-shadow: none;
            outline: none
          ;
          }

        }
      }

      .rt-tbody{
          .rt-td{
            display: flex;
            justify-content: center;
            align-items: center;
            //height: 60px;
            flex-wrap: wrap;
            border: none;
            white-space: normal;
            width: auto;
          }
      }
    }

    .mobileTable {
      border-radius: 11px;
      box-shadow: 1px 2px 17px 0 rgba(170, 199, 217, 0.3);
      border: solid 1px $peacock-blue;
      background-color: $white;
      padding: 20rem;
      .service-wrapper {
        padding: 20rem 0;
        border-bottom: solid 1px $peacock-blue;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
          border-bottom: none;
        }
        .service-icon-wrapper {
          flex: 1;
          > .icon {
            width: 50rem;
            height: 50rem;
          }
          > .label {
            font-size: 20rem;
            text-align: center;
            color: $peacock-blue;
          }
        }
        .details-wrapper {
          flex: 4;
          .section {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 5rem 0;
            margin: 0 20rem;
            border-bottom: solid 0.3rem rgba(0,103,165, 0.8);
            &:last-of-type {
              border-bottom: none;
            }
            .field-wrapper {
              text-align: right;
              &:first-of-type {
                margin-left: 50rem;
              }
              > .label {
                color: $mushroom;
                font-size: 14rem;

              }
              > .value {
                color: #282525;
                font-size: 20rem;
              }
            }
          }
        }
      }
    }
  }
}
