.bottom-titles-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  .bottom-first-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    .bottom-title-red {
      color: #d82733;
      font-size: 35rem;
      margin-left: 6px;
      font-weight: bold;
      //margin-bottom: 10px;

    }

    .bottom-title-blue {
      color: #0067a5;
      font-size: 35rem;
      margin-left: 6px;
      font-weight: bold;
    }

  }
  .bottom-title {
    color: #0067a5;
    font-size: 30rem;
  }
}
