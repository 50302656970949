@import "../../../styles/colors";
@import "../../../styles/fonts";

.membershipForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .together {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .input-group {
    .label-input {
      display: flex;
      justify-content: space-between;
      background-color: $very-light-pink;

      .arrow {
        width: 12rem;
        cursor: pointer;
      }

      .deleteMulti {
        width: 8rem;
        padding: 4rem;
        cursor: pointer;
      }

      .label-wrapper {
        display: none;
      }

      input.input-field {
        @include input-field;
        color: $peacock-blue;
        width: 100%;
        min-width: initial;
        height: 20rem;
        border: none;
        padding: 4rem 10rem;
        text-align: start;
        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }


      input:focus {
        outline: none;
      }

      input::placeholder {
        @include input-field;
        color: $mushroom;

      }
    }
  }

  .error-color {
    color: $red;
  }

  .input-error-message {
    text-align: end;
    padding: 5rem 0;
    font-size: 15rem;
    color: $red;
  }
}
