@import "fonts";
@import "colors";

.terms {
  height: 60vh;
  overflow-y: auto;
  padding: 10rem 30rem 30rem 30rem;
  .title {
  }
  .content {
    white-space: pre-wrap;
  }
}