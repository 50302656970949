@import "colors";

.checkbox-btn {
  display: flex;
  cursor: pointer;
  .checkbox-btn__checkbox-custom {
    border: 1rem solid $warm-grey;
    background-color: $very-light-pink;
  }

  &:hover {

    .checkbox-btn__checkbox-custom {
      border-color: $warm-grey;
    }

    .checkbox-btn__label {
      color: $peacock-blue;
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: default;

    .checkbox-btn__checkbox-custom {
      transition: 0s;

      //@include themify($themes) {
      //  background-color: themed('colorHover');
      //  border-color: themed('colorFieldsBorder');
      //}

      border-color: #dddddd;

      svg {
        fill: #dddddd;
      }
    }

    .checkbox-btn__label {
      color: #dddddd;
    }
  }

  &.checkbox-btn--colored {

    .checkbox-btn__checkbox-custom {
      border-color: $warm-grey;
      background-color: $very-light-pink;

      svg {
        fill: #ffffff;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__checkbox-custom {
        border-color: $warm-grey;
        background-color: $very-light-pink;
      }
    }
  }

  &.checkbox-btn--colored-click {

    .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {
      border-color: $warm-grey;
      background-color: $very-light-pink;

      svg {
        fill: #ffffff;
      }
    }

    &.disabled {

      .checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {

        //@include themify($themes) {
        //  background-color: themed('colorHover');
        //  border-color: themed('colorFieldsBorder');
        //}

        svg {
          fill: #dddddd;
        }
      }
    }
  }

  &.checkbox-btn--button {
    background: $peacock-blue;
    min-width: 150rem;
    color: #ffffff;
    height: 24rem;
    border-radius: 4rem;
    transition: all 0.3s;
    padding: 0 6rem;
    width: 100%;

    .checkbox-btn__label {
      color: #ffffff;
    }

    .checkbox-btn__checkbox-custom {
      display: none;
    }

    //.checkbox-btn__label-svg {
    //  //margin: auto 4rem auto auto;
    //  height: 18rem;
    //  line-height: 1rem;
    //
    //  svg {
    //    fill: #ffffff;
    //    width: 16rem;
    //    height: 16rem;
    //  }
    //
    //  .checkbox-btn__label-check {
    //    display: none;
    //  }
    //}

    .checkbox-btn__checkbox:checked ~ .checkbox-btn__label-svg {

      .checkbox-btn__label-check {
        display: block;
      }

      .checkbox-btn__label-uncheck {
        display: none;
      }
    }

    .checkbox-btn__label {
      margin: auto auto auto 0;
      padding: 0;
    }

    &:hover {
      background: $peacock-blue;

      .checkbox-btn__label {
        color: #ffffff;
      }
    }

    &.disabled {
      opacity: 0.4;

      .checkbox-btn__label {
        color: #ffffff;
      }
    }
  }
}

.checkbox-btn__checkbox {
  display: none;
}

.checkbox-btn__checkbox-custom {
  position: absolute;
  width: 18rem;
  height: 18rem;
  border-radius: 3rem;

  //@include themify($themes) {
  //  border: 1rem solid themed('colorIcon');
  //}

  svg {
    transition: all 0.3s;
    opacity: 0;
    height: 16rem;
    width: 16rem;
    fill: $peacock-blue;
    //margin-top: -6rem;
  }
}

.checkbox-btn__label {
  line-height: 18rem;
  padding-right: 28rem;
  text-align: start;

  //@include themify($themes) {
  //  color: themed('colorText');
  //}
}

.checkbox-btn__checkbox-custom,
.checkbox-btn__label {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s;
}

.checkbox-btn__checkbox:checked + .checkbox-btn__checkbox-custom {

  svg {
    opacity: 1;
  }
}
