@import "../../../styles/colors";
@import "../../../styles/fonts";

.personalForm {
  display: inline-block;

  .input-group {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 20rem;

    .label-input {
      display: flex;
      justify-content: space-between;

      .arrow {
        width: 12rem;
        cursor: pointer;
      }

      .deleteMulti {
        width: 8rem;
        padding: 4rem;
        cursor: pointer;
      }

      .label-wrapper {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 20rem;
        min-width: 75rem;
        @include Text-Style-Text-Area;

        label {
          @include Text-Style-6;
        }
      }


      input.input-field {
        @include Text-Style-Text-Area;
        min-width: 200rem;
        height: 35rem;
        border: none;
        border-bottom: 1rem solid $mid-green;
        padding: 10rem 0;
        text-align: start;

      }


      input:focus {
        outline: none;
      }

      input::placeholder {
        @include Text-Style-Text-Area-Placeholder;

      }
    }
  }

  .error-color {
    color: $red;
  }

  .input-error-message {
    text-align: end;
    padding: 5rem 0;
    font-size: 15rem;
    color: $red;
  }


}