.membershipForm {

  &.paymentForm {
    display: flex;

    .full-width{
      width: 100%;
    }

    .input-group{
      min-width: 138px;

      .label-input{

        input.input-field {
        }
      }
    }
    .fields-container{
      display: flex;
      flex-direction: row;
      max-width: 700rem;
      flex-wrap: wrap;
    }

    .buttons-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }



    }
}
