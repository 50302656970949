@import "colors";
@import "fonts";

.button {
  @include button;
  min-width: 100rem;
  display: inline-block;
  padding: 10rem 22rem;
  cursor: pointer;
  text-decoration: none;
  border-radius: 24rem;
  //border: solid 1px $peacock-blue;
  background-color: $peacock-blue;
}

button {
  border: none;
}

button:focus {
  outline: 0;
}

button:active {
  outline: 0;
}