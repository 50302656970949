@import "../../styles/fonts";


  .loginWrapper {
    flex: 2;
    padding: 50rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .formHolder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 50%;

      .formWrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        border-radius: 11rem;
        box-shadow: 1px 2px 8px 0 rgba(170, 199, 217, 0.3);
        border: solid 1px #0067a5;
        padding: 20rem;

        .heading {
          margin: 60rem;
          font-size: 30rem;
          max-width: 460rem;
        }

        .form {
          align-items: center;

          .input-group {
            margin: 0;
          }

          .inputs-wrapper {
            min-width: 300rem;
            display: flex;
            flex-direction: column;
          }

          .input-error-message {
            font-size: 15rem;
            top: 30rem;
          }
        }
      }
    }
  }
