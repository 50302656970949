@import "colors";
@import "fonts";

.layoutUser {
  .headerHolder {
    box-sizing: border-box;
    //padding: 20rem;
    display: flex;
    flex-direction: column;

    .topbar-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      min-height: 75rem;
      width: 100%;
      padding: 20rem;
      position: relative;
      color: #000;
      .topbar-user-icon{
        width: 36rem;
        padding: 2rem;
        border: 1px solid $peacock-blue;
        border-radius: 50%;
      }

      .dropdown-container{
        width: 130rem;
        height: 71rem;
        box-shadow: 2rem 2rem 9rem 0 rgba(180, 180, 180, 0.5);
        background-color: $white;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: absolute;
        top: 70rem;
        left: 24rem;
        z-index: 100;
        .dropdown-item{
          flex:1;
          display: flex;
          align-items: center;
          padding: 5rem;
          &:hover{
            background-color: rgba(0, 103, 165, 0.08);
          }
        }
      }

      .topbar-links-container {
        display: flex;
        flex-direction: row;

        .topbar-link {
          margin: 0 10rem;
          font-size: 24rem;
          color: black;
          &:hover {
            color: $peacock-blue;
          }
          .active-topbar-link-underline {
            width: 45%;
            height: 3rem;
            background-color: #d82733;
            visibility: hidden;
          }

          &.active-topbar-link{
            .active-topbar-link-underline {
              visibility: unset;
            }
          }
        }


      }
    }

    .logo-container{
      width: 100%;
      height: 108rem;
      background-image: linear-gradient(87deg, #b7daef -83%, $peacock-blue 100%);
      position: relative;
      clip-path: polygon(0% -60%,100% -60%,100% 25%,0% 100%);

      .logo-container-redline{
        height: 7rem;
        background-color: #d82733;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

    }

      .title {
        flex: 8;
        padding: 20rem;
        @include Text-Style-24;

      }

      .logout {
        flex: 2;
        padding: 0 20rem 20rem 20rem;
        display: flex;
        justify-content: flex-end;
      }
    }
  @media only screen and (max-width: 599px) {
    .headerHolder {
      .topbar-container {
        padding: 0;
      }
    }
  }
}


.sidebar-links-container {
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20rem;
  height: 100%;
  a {
    text-decoration: none;
  }
  .topbar-link {
    margin: 10rem;
    font-size: 30rem;
    color: black;
    &:hover {
      color: $peacock-blue;
    }
    .active-topbar-link-underline {
      width: 50%;
      height: 5rem;
      background-color: #d82733;
      visibility: hidden;
    }

    &.active-topbar-link{
      font-weight: bold;
      .active-topbar-link-underline {
        visibility: unset;
      }
    }
  }
  .logout-button {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
}
