.layoutUser {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;

  .container {
    flex-grow: 1;
    display: flex;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20rem;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-grow: 1;
    }

  }
}
