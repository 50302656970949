@import "fonts";
@import "colors";

.childFields {
  display: flex;
  align-items: flex-start;

  .label {
    @include Text-Style-6;
    margin: 67rem 20rem 0 20rem;
    white-space: nowrap;
  }

  .fields {
    display: flex;

    .input-group {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      margin: 20rem;

      .label-input {
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        .label-wrapper {
          @include Text-Style-6;
          margin: 0;
        }


        input.input-field {
          @include Text-Style-Text-Area;
          width: 167rem;
          height: 35rem;
          border: none;
          border-bottom: 1rem solid $mid-green;
          padding: 10rem 0;
          text-align: start;

        }


        input:focus {
          outline: none;
        }

        input::placeholder {
          @include Text-Style-Text-Area-Placeholder;

        }
      }
    }

    .error-color {
      color: $red;
    }

    .input-error-message {
      text-align: end;
      padding: 5rem 0;
      font-size: 15rem;
      color: $red;
    }
  }
}
