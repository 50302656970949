@import "fonts";
@import "colors";

.sidebar {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;

  .heading {
    @include Text-Style-5;
    text-align: start;
    margin-top: 50rem;
    padding: 20rem;
    width: 188rem;

  }

  .line {
    border: solid 1rem $spruce;
  }

  .links {
    padding: 20rem;

    .item {
      display: flex;
      margin: 60rem 10rem;
      justify-content: flex-start;
      align-items: center;

      .image {
        width: 56rem;
        box-sizing: border-box;
        margin: 0 0 0 15rem;
      }

      .label {
        @include Text-Style-17;
        height: 40rem;
        line-height: 36rem;
      }


    }

    .active {

      .image {
        border: solid 2rem $spruce;
        border-radius: 50%;
      }

      .label {
        @include Text-Style-16;
      }
    }

  }
}