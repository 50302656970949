@import "fonts";
@import "colors";

$cardSize: 250rem;
$padding: 25rem;
.cardHolder {
  padding: 20rem;

  .card {
    box-sizing: border-box;
    width: $cardSize;
    height: $cardSize;
    padding: $padding 0;
    box-shadow: 1px 2px 17px 0 rgba(170, 199, 217, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    border-radius: 12rem;
    transition: all 400ms ease-in;
    &.selected{
      box-shadow: 1px 2px 17px 0 rgba(170, 199, 217, 0.3);
      border: solid 4px $peacock-blue;

      &:hover{
        box-shadow: 1px 2px 17px 0 rgba(170, 199, 217, 0.3);
        border: solid 4px $peacock-blue;
      }

    }

    &:hover{
      box-shadow: 1px 2px 17px 0 rgba(0, 103, 165, 0.5);
      border: solid 0 $peacock-blue;
    }





    .cardImageHolder {
      display: flex;
      box-sizing: border-box;
      width: 100rem;
      height: 100rem;
      justify-content: center;

      .cardImage {
        //margin: 50rem;
        object-fit: contain;
        width: 80%;

      }
    }

    .cardLabel {
      @include headline;
      font-size: 25rem;
    }
  }

  @media only screen and (max-width: 599px) {
    padding: 20rem;
  }
}
