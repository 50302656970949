@import "../../styles/fonts";

.membershipHolder {
  width: 100%;
  min-height: 100vh;
  display: flex;

  .coverImage {
    width: 40%;
    justify-content: center;
    background-image: url("../../images/car.png");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .memberForm {
    width: 60%;
    padding: 50rem;
    .logo {
      width: 130rem;
    }
    .title {
      @include headline;
      margin: 20rem 0;
    }
    .sub-title {
      @include headline-second;
      margin: 20rem 0;
    }
    .required-text {
      padding: 10rem;
      align-self: flex-start;
      box-sizing: border-box;
      .required-label {
        @include small-body;
        padding-right: 30rem;
        text-align: right
      }
    }
  }

  .contact {
    width: 100%;
    max-width: 1000rem;
    text-align: start;
    padding: 20rem;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;


    .info {
      flex-grow: 1;
      padding: 20rem;

      .heading {
        @include Text-Style-SemiHeading;
        padding: 20rem;
      }

      .desc {
        padding: 20rem;
        @include Text-Style-Contact;
      }
    }

    .contactImage {
      flex-grow: 2;
      padding: 50rem;
      width: 427rem;
    }

  }
  .modal {
    &.congrats {
      padding: 30rem;
      .title {
        @include headline;
        color: $black;
        margin-top: 20rem;
      }
      .sub-title {
        @include headline-second;
      }
    }
  }
}