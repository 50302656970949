@import '../../../styles/fonts';

.services-screen-container {
  flex:1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  //padding-top: 10rem;
  padding-bottom: 50rem;
  align-items: center;


  .title{
    @include main-headline;
    font-size: 40rem;
  }

  .title-secondary{
    @include headline-second;
    font-size: 40rem;
    margin-bottom: 20rem;

  }


  .cards-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transform: translateY(0rem);
    transition: transform 400ms ease-in;

    &.card-selected {
      transform: translateY(-5rem);
      .cardHolder {
        padding: 10rem;
        .card {
          width: 220rem;
          height: 220rem;
        }
      }
    }
  }

  .payment-summary-container {
    min-height: 210rem;
    max-width: 90%;
    width: 100%;
    border-radius: 11rem;
    box-shadow: 1rem 2rem 17rem 0 rgba(170, 199, 217, 0.3), 1rem 2rem 17rem 0 rgba(170, 199, 217, 0.35);
    border: solid 4rem $peacock-blue;
    background-color: $white;
    margin-bottom: 100rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .details-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
    }
    .image-container{
      flex: 1.5 1;
      display: flex;
      flex-direction: column;
      font-size: 25rem;
      color: #0067a5;
      font-weight: bold;
      align-items: center;
      justify-content: space-evenly;
    }

    .requested-service-details {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      font-size: 22rem;
      padding: 20rem 0;

    }

    .requested-service-cost{
      flex: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20rem 0;
    }

    .requested-service-comments{
      flex: 2;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20rem 0;
    }

    .text-line{
      display: flex;
      flex-wrap: wrap;
      text-align: right;
      align-items: center;
      .label {
        font-weight: bold;
      }
      .value {
        padding: 0 8rem;
      }
    }

    .headline{
      text-decoration: underline;
      font-size: 26rem;
      text-align: right;
    }
  }



  .form-container {
    //height: 35vh;
    width: calc(100% - 40rem);
    background-color: #fbfbfb;
    border-radius: 20rem 20rem 0 0;
    //box-shadow: 3rem -2rem 1rem 0 rgba(205, 205, 205, 0.5);
    box-shadow: 0 0 15rem 0 rgba(205, 205, 205, 0.5);
    position: absolute;
    bottom: 0;
    transform: translateY(35vh);
    transition: transform 400ms ease-in;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    &.show{
      transform: translateY(0rem);
      justify-content: flex-start;
      //padding: 10rem 0;
      overflow: visible;
      .close-form {
        position: absolute;
        top: 20rem;
        right: 20rem;
        width: 20rem;
        height: 20rem;
        cursor: pointer;
        &:hover {
          opacity: 0.5;
        }
      }
      .form-header{
        font-size: 22rem;

      }

      .commercial {
        width: 310rem;
        height: 128rem;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -20rem;
        top: 60rem;
        background-image: url('../../../images/banner.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        .content {
          font-size: 26rem;
          //font-weight: bold;
          color: $white;
          margin-bottom: 22rem;
        }
      }
      .form-fields-container{
        width: 100%;
        min-height: 200rem;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        max-width: 520rem;
        padding: 10rem;
      }
    }
  }
  .popup-content {
    &.payment-confirmation-modal-content {
      //height: 633rem;
      width: 65% !important;
      padding: 40rem !important;
      border-radius: 11rem;
      box-shadow: 1rem 2rem 17rem 0 rgba(170, 199, 217, 0.3) !important;
      border: solid 4rem $peacock-blue !important;
      background-color: $white;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .headline{
        @include headline;
        color: black;
        font-size: 40rem;
      }

      .text-line{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .label {
          font-weight: bold;
        }
        .value {
          padding: 0 8rem;
        }
      }

      .requested-service-details {
        padding: 30rem 0;
        .bottom-container {
          display: flex;
          width: 70%;
          margin: 0 auto;
          font-size: 18rem;
          text-align: right;
          line-height: 2em;
          flex: 1;
          flex-direction: row;
          .details-wrapper {
            flex: 1;
          }
          .requested-service-comments {
            flex: 1;
            .text-line {
              display: initial;
              line-height: 1.4em;
              margin: 2rem 0;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .cards-container {
      &.card-selected {
        //transform: translateY(-60rem);
        //.card {
        //  max-width: 20vw;
        //}
      }
    }
    .form-container {
      &.show {
        .commercial {
          position: relative;
          order: 1;
          top: 0;
          width: 100vw;
          background-size: cover;
          right: 0;
        }
      }
    }
    .payment-summary-container {
      flex-direction: column;
      height: auto;
      max-width: initial;
      padding: 10rem;
      box-sizing: border-box;
      //font-size: 22rem;
      .details-wrapper {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .requested-service-comments {
        padding: 0;
      }
      //.headline, .image-container {
      //  display: none;
      //}

    }
    .popup-content {
      &.payment-confirmation-modal-content {
        width: 80% !important;
        .text-line {
          justify-content: center;
        }
        .requested-service-details {
          .bottom-container {
            flex-direction: column;
            text-align: center;
            align-items: center;
          }
        }
      }
    }
  }
}
