@import "fonts";

.wizard {
  margin: 20rem 20rem 5rem 20rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .go-back {
    display: flex;
    align-items: center;
    margin: 0 20rem;
    cursor: pointer;

    .icon {
      width: 12rem;
      padding: 3rem;
    }

    .label {
      @include Text-Style-Text-Area-Placeholder;
      padding: 3rem;
      margin: 0 0 5rem 0;
    }

  }

  .progress-bar {
    display: flex;


    .progress-bar-text{
      color: #0067a5;
      font-size: 15rem;

      &.bold{
        font-weight: bold;
        margin: 0 5px;
      }
    }


    .bar-item-wrapper {
      display: flex;
      align-items: flex-start;

      .line {
        width: 42rem;
        height: 3rem;
        border-radius: 20rem;
        box-shadow: inset 0 1rem 0 0 rgba(0, 0, 0, 0.29);
        background-color: $spruce;
        margin: 21rem 5rem 5rem 5rem;
      }

      .bar-item {
        display: flex;
        align-items: center;
        flex-direction: column;

        .icon {
          width: 12rem;
        }

        .status {
          @include Text-Style-25;
          width: 42rem;
          height: 42rem;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .after {
          background-color: $spruce;
        }

        .current {
          border: 6rem solid $spruce;
        }

        .before {
          border: 3rem solid $spruce;
        }

        .label {
          @include Text-Style-4;
          width: 82rem;
          margin-top: 50rem;
          position: absolute;
        }
      }
    }
  }
}