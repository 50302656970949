@import "colors";
@import "fonts";

.input-group {
  display: flex;
  flex-direction: column;
  margin-top: 20rem;

  .label-wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 5rem;
    padding-left: 5rem;

    img {
      width: 24rem;
      margin-right: 12rem;
    }

    label {
      @include Text-Style-3;
    }
  }

  .input-wrapper {
    position: relative;
    width: 100%;

    .input-icon-wrapper {
      position: absolute;
      display: flex;
      align-items: center;
      left: 15rem;
      height: 34rem
    }
  }

  .label-input {
    background-color: $very-light-pink;
    min-height: 30rem;
    display: flex;
    align-items: center;
  }

  input.input-field {
    @include input-field;
    color: $peacock-blue;
    width: 100%;
    height: 35rem;
    border: none;
    padding: 4rem 10rem;
    box-sizing: border-box;
    background-color: transparent;


    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  input:focus {
    outline: none;
  }

  input::placeholder {
    @include input-field;
    color: $mushroom;
  }

}

.error-color {
  color: $red;
}

.input-error-message {
  text-align: end;
  padding: 5rem 0;
  font-size: 25rem;
  color: $red;
}

