.membershipForm {

  &.checkBeforePurchaseForm {
    display: flex;
    flex-direction: row;

    .full-width{
      width: 100%;
    }

    .input-group{
      min-width: 138px;

      .label-input{

        input.input-field {
        }
      }
    }
    .buttons-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20rem;
    }

  }
}
