@import "../../../styles/fonts";

.profile-screen-container {


  .title {
    @include main-headline;
    font-size: 40rem;
  }

  .sub-title {
    @include headline-second;
    height: 40rem;
  }
  .details-container {
    font-size: 18rem;
    margin: 20rem auto;
    border-radius: 11rem;
    box-shadow: 1rem 2rem 17rem 0 rgba(170, 199, 217, 0.3);
    border: solid 4rem $peacock-blue;
    padding: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .profileForm {
      .text-line {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        .input-group {
          flex: auto;
          //width: 100%;
        }
        .label-input {
          background-color: transparent;

          .input-field {
            flex: 1;
            color: $black;
            font-size: inherit;
            background-color: $pale-grey;

            &:disabled {
              background-color: transparent;
              cursor: initial;
              opacity: 1;
            }
          }

          .label-wrapper {
            padding: 0;
            //white-space: nowrap;
            > label {
              color: $peacock-blue;
              font-weight: bold;
              margin-left: 10rem;
              font-size: inherit;
            }
          }
        }
      }

      .cars {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20rem;
        .label {
          color: $peacock-blue;
          font-weight: bold;
        }
        .cars-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          margin-top: 20rem;

          .license-plate {
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            border-radius: 14rem;
            background-color: $pale-grey;
            padding: 0 10rem;
            margin: 10rem;
            .number {
              //font-size: 24rem;
              color: $black;
              margin: 0 4rem;
            }
            .delete-car {
              border: none;
              box-shadow: none;
              background-color: transparent;
              padding: 0;
              margin: 0 4rem;
              &:hover {
                opacity: 0.5;
                cursor: pointer;
              }
              > img {
                width: 10rem;
              }
            }
          }
          .input-error-message {
            position: initial;
          }
          .button {
            > img {
              width: 28rem;
            }
            &:hover {
              opacity: 0.6;
            }
            &:disabled {
              background-color: $pale-grey;
            }
          }
        }
      }
    }
    .text-line {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      .label-wrapper {
        > label {
          color: $peacock-blue;
          font-weight: bold;
          margin-left: 10rem;
          font-size: inherit;
        }
      }
      .field-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-of-type {
          flex-direction: column;
          margin-top: 40rem;
        }
        .label {
          color: $peacock-blue;
          font-weight: bold;
          margin-left: 10rem;
        }
        .cars-container {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: flex-start;
          width: 100%;
          margin-top: 20rem;
          .license-plate {
            white-space: nowrap;
            border-radius: 14rem;
            background-color: $pale-grey;
            padding: 0rem 10rem;
            margin: 10rem
          }
        }
      }
    }
    .new-car-modal-content {
      border-radius: 14rem;
      box-shadow: 1px 2px 15px 0 #8d8d8d;
      border: solid 2px $peacock-blue !important;
    }
  }

  @media only screen and (max-width: 599px) {
    .details-container {
      width: 100%;
      box-sizing: border-box;
      font-size: 26rem;
      .profileForm .cars .cars-container {
        justify-content: space-between;
        > * {
          width: calc(50% - 44rem) !important;
          box-sizing: content-box;
          justify-content: space-around !important;
    }
  }
  .text-line {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.5rem;
    .field-wrapper {
      margin: 4rem 0;
      align-items: flex-start;
      .cars-container {
        justify-content: space-between;
        .license-plate {
          margin: 10rem 0;
          width: 40%;
        }
      }
    }
  }
  .new-car-modal-content {
    width: 80% !important;
  }
}
}
}
