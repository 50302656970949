.carsBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-end;

  .car {
    max-width: 100rem;
    max-height: 65rem;
  }
}