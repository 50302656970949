$teal-green: #25ab79;
$slate-grey: #5b5a5f;
$spruce: #0c734d;
$coral-78: rgba(250, 97, 53, 0.78);
$white: #ffffff;
$white-57: rgba(247, 247, 247, 0.57);
$pinkish-grey: #cccccc;
$greyish: #adadad;
$pale-grey: #f1f2f3;
$mid-green: #49a044;
$dark-pastel-green: #5fb353;
$ice-blue: #eef6ff;
$coral: #fa6135;
$red: #cb0000;
$dark-grey-blue: #314459;
$light-grey-green-80: rgba(166, 212, 159, 0.8);
$transparent: transparent;


$peacock-blue: #0067a5;
$black: #1a1919;
$mushroom: #a19a9a;
$pale-grey: #f6f6f7;
$very-light-pink: #ededed;
$warm-grey: #979797;