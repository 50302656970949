@import "colors";
@import "fonts";

.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;

  .input-group {
    flex: 1;
    position: relative;
    justify-content: flex-start;
    flex-direction: column;
    margin: 12rem 10rem;
    min-width: 100rem;
    &.hidden {
      display: none;
    }
  }

  .form-error {
    font-size: 30rem;
    margin-top: 25rem;
    color: $red !important;
  }

  .input-error-message {
    text-align: end;
    padding: 5rem 0;
    font-size: 15rem;
    color: $red;
    position: absolute;
    top: 25rem;
  }

  input[type="number"] {
    direction: ltr;
    text-align: right;
  }
  .modal {
    .close {
      font-size: 34rem;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 10rem;
    }
  }
}
